function Banner() {
  return (
    <>
      <section className="banner-section">
        <div className="container">
          <div className="banner-content">
            <div className="banner-content__text">
              <h2>Save big with our cheap car rental!</h2>
              <p>
                To Book Car{" "}
                <a href="#booking-section">
                  <span>Click Here</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
