import Footer from "../components/Footer";
import React from "react";
import HeroPages from "../components/HeroPages";
import Testimonials from "../components/Testimonials";
import { Helmet } from "react-helmet";
import { db } from "../components/firebase.js";
import {
  doc,
  getDocs,
  setDoc,
  onSnapshot,
  collection,
  addDoc,
} from "firebase/firestore";

function TestimonialsPage() {
  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [question, setQuestion] = React.useState(null);

  const postmsg = async () => {
    try {
      // Validate input fields
      if (!name || !email || !question) {
        window.alert("Please Fill All Fields!");
        return;
      }
  
      // Get the current date
      const currentDate = new Date();
  
      // Format the date as "YYYY-MM-DD"
      const formattedDate = currentDate.toISOString().split("T")[0];
  
      // Add a new document to the "Messages" collection with the specified fields
      await db.collection("Feedback").add({
        cname: name,
        cemail: email,
        cquestion: question,
        date: formattedDate,
      });
  
      window.alert("Your feedback is sent!");
  
      // Reload the page
      window.location.reload();
      
      console.log("Contact Details Posted!");
    } catch (error) {
      // Log any errors to the console
      console.error(error);
      window.alert("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Testimonials - CNC Cars Rental</title>

        <meta
          name="description"
          content="Explore customer testimonials and reviews for CNC Cars Rental. Find the best car rental deals in Central Andros, Bahamas. Affordable, reliable, and top-quality car renting services. Your ultimate car renting experience awaits with CNC CAR & SCOOTER RENTAL!"
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <section className="testimonial-page">
        <HeroPages name="Testimonials" />
       

        <div style={{ display: "flex" }}>
          <div
            className="contact-div__form"
            id="feedback"
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "30px",
            }}
          >
            <h1 style={{ marginTop: "25px", marginBottom: "10px" }}>
              Leave Your Feedback
            </h1>
            <h3 style={{ marginBottom: "35px" }}>
              We Highly Regard Our Customer Opinion
            </h3>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                postmsg();
              }}
            >
              <label>
                Full Name <b>*</b>
              </label>
              <input
                type="text"
                placeholder='E.g: "Joe Shmoe"'
                onChange={(e) => setName(e.target.value)}
              />

              <label>
                Email <b>*</b>
              </label>
              <input
                type="email"
                placeholder="youremail@example.com"
                onChange={(e) => setEmail(e.target.value)}
              />

              <label>
                Your Valuable Feedback <b>*</b>
              </label>
              <textarea
                placeholder="Your valuable feedback will allow us to improve our services..."
                onChange={(e) => setQuestion(e.target.value)}
              ></textarea>

              <button type="submit">
                <i className="fa-solid fa-envelope-open-text"></i>&nbsp; Submit
                Feedback
              </button>
            </form>
          </div>
        </div>
        <Testimonials />

        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div
              className="text-content"
              style={{
                display: "flex",
                alignItems: "center" /* Center vertically */,
                justifyContent: "center",
              }}
            >
              <h2> To Book Car </h2>
              <p>
                <a href="/#booking-section">
                  <span>Click Here</span>
                </a>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default TestimonialsPage;
