import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import HeroPages from "../components/HeroPages";
import React from "react";
import { db } from "../components/firebase.js";
import {
  doc,
  getDocs,
  setDoc,
  onSnapshot,
  collection,
  addDoc,
} from "firebase/firestore";
import { Helmet } from "react-helmet";

function Contact() {
  {
    /*
  const carDatas = [
    
  
    {
      name: "Nissan Note 2012",
      price: "80",
      img: 'https://i.pinimg.com/736x/83/48/d0/8348d0e3985ba4f16463d39f900271d6.jpg',
      air: "Compact",
      transmission: "Automatic",
      fuel: "petrol",
    },
  
    {
      name: "Nissan Note 2013",
      price: "80",
      img: 'https://wieck-nissanao-production.s3.amazonaws.com/photos/887cd19ec61fb3c5cfb090142f0fdaff0b3d378a/preview-768x432.jpg',
       air: "Compact",
      transmission: "Automatic",
      fuel: "petrol",
    },
  
    {
      name: "Honda Fit",
      price: "80",
      img: 'https://i.pinimg.com/736x/b0/d2/de/b0d2de3ccad3d910e43cd297af31cb3f.jpg',
      air: "Compact",
      transmission: "Automatic",
      fuel: "petrol",
    },

    {
      name: "Nissan Cube",
      price: "80",
      img: 'https://cache4.pakwheels.com/system/car_generation_pictures/5911/original/Nissan_Cube_2015.jpg?1632833729',
      air: "Compact",
      transmission: "Automatic",
      fuel: "petrol",
    },
  
    {
      name: "Honda Fit Hybrid",
      price: "80",
      img: 'https://www.mad4wheels.com/img/free-car-images/mobile/13659/honda-fit-hybrid-2014-398763.jpg',
      air: "Midsize",
      transmission: "Automatic",
      fuel: "hybrid",
    },
  
    {
      name: "Suzuki Swift",
      price: "80",
      img: 'https://cache4.pakwheels.com/system/car_generation_pictures/5247/original/swift.jpg?1595595071',
       air: "Compact",
      transmission: "Automatic",
      fuel: "petrol",
    },
   
    {
      name: "Nissan Tiida",
      price: "80",
      img: 'https://cache1.pakwheels.com/system/car_generation_pictures/4249/original/nissan-tiida.jpg?1453988506',
       air: "Midsize",
      transmission: "Automatic",
      fuel: "petrol",
    },
  
    {
      name: "Nissan Teana",
      price: "80",
      img: 'https://imgcdn.zigwheels.my/large/gallery/color/30/275/nissan-teana-color-338492.jpg',
    
       air: "Midsize",
      transmission: "Automatic",
      fuel: "petrol",
    },
  
    {
      name: "Nissan Xtrail",
      price: "80",
      img: 'https://carsales.pxcrush.net/car/spec/niss2625.jpg?pxc_method=gravityfill&pxc_bgtype=self&pxc_size=720,480&watermark=683164540',
      
       air: "Suv/Jeep",
      transmission: "Automatic",
      fuel: "petrol",
    },
  
    {
      name: "Nissan Serena",
      price: "80",
      img: 'https://wieck-nissanao-production.s3.amazonaws.com/photos/cd5a49f1b4a85fa424a467a93da985b03afde782/preview-768x432.jpg',
     
      air: "* Seater",
      transmission: "Automatic",
      fuel: "petrol",
    },
  
  ];

  const addCarsToFirestore = async () => {
    const carsCollection = collection(db, 'carsdata');
  
    for (const car of carDatas) {
      try {
        await addDoc(carsCollection, car);
        console.log(`Car ${car.name} added to Firestore`);
      } catch (error) {
        console.error(`Error adding car ${car.name} to Firestore:`, error);
      }
    }
  };
  
 
    useEffect(() => {
      addCarsToFirestore();
    }, []); // This will run the function only once on component mount
  

  */
  }

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [question, setQuestion] = React.useState("");

  const postmsg = async () => {
    try {
      // Validate input fields
      if (!name || !email || !question) {
        window.alert("Please Fill All Fields!");
        return;
      }
  
      // Get the current date
      const currentDate = new Date();
  
      // Format the date as "YYYY-MM-DD"
      const formattedDate = currentDate.toISOString().split("T")[0];
  
      // Add a new document to the "Messages" collection with the specified fields
      await db.collection("Messages").add({
        cname: name,
        cemail: email,
        cquestion: question,
        date: formattedDate,
      });
  
      window.alert("Your message is sent!");
  
      // Reload the page
      window.location.reload();
      
      console.log("Contact Details Posted!");
    } catch (error) {
      // Log any errors to the console
      console.error(error);
      window.alert("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - CNC Cars Rental</title>

        <meta
          name="description"
          content="Get in touch with CNC Cars Rental for all your car rental needs. Explore our diverse fleet of well-maintained vehicles and experience hassle-free transportation in Central Andros, Bahamas. Contact us today for inquiries, reservations, or any assistance you may need. Your ultimate car renting experience begins with CNC CAR & SCOOTER RENTAL!"
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <section className="contact-page">
        <HeroPages name="Contact" />
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="contact-div">
                <div className="contact-div__text">
                  <a
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "green",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href="https://maps.app.goo.gl/UGqFFZBpH6wPW2Ja7"
                  >
                    Click For Google Maps Location
                  </a>

                  <a
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                    href="tel:+1 (242) 464-3036"
                  >
                    &#x260E; +1 (242) 464-3036
                  </a>
                  <a
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                    href="mailto: chrispinstore@gmail.com"
                  >
                    &#9993; chrispinstore@gmail.com
                  </a>
                  <a style={{ fontWeight: "bold", fontSize: "18px" }} href="/">
                    &#128205; Rev. Leroy Hanna Dr Love Hill Subdivision, Central
                    Andros, Bahamas
                  </a>
                  <br></br>
                  <h3>Need additional information?</h3>
                  <p>
                    Explore convenient and affordable vehicle rental with CNC
                    Cars Rental in Ev. Leroy Hanna Dr Love Hill Subdivision,
                    Central Andros, Bahamas. Our diverse fleet of
                    well-maintained vehicles ensures reliable transportation
                    solutions for your exploration needs in the beautiful
                    Bahamas. Enjoy seamless travel experiences with CNC CAR &
                    SCOOTER RENTAL – your trusted partner for exploring Central
                    Andros. Book now at{" "}
                    <a
                      href="https://cnc-car-scooter-rental.business.site/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      office
                    </a>{" "}
                    for an unmatched journey.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-div__form">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    postmsg();
                  }}
                >
                  <label>
                    Full Name <b>*</b>
                  </label>
                  <input
                    type="text"
                    placeholder='E.g: "Joe Shmoe"'
                    onChange={(e) => setName(e.target.value)}
                  />

                  <label>
                    Email <b>*</b>
                  </label>
                  <input
                    type="email"
                    placeholder="youremail@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <label>
                    Tell us about it <b>*</b>
                  </label>
                  <textarea
                    placeholder="Write Here.."
                    onChange={(e) => setQuestion(e.target.value)}
                  ></textarea>

                  <button type="submit">
                    <i className="fa-solid fa-envelope-open-text"></i>&nbsp;
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div
              className="text-content"
              style={{
                display: "flex",
                alignItems: "center" /* Center vertically */,
                justifyContent: "center",
              }}
            >
              <h2> To Book Car </h2>
              <p>
                <a href="/#booking-section">
                  <span>Click Here</span>
                </a>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Contact;
