import MainImg from "../images/chooseUs/main.png";
import Box1 from "../images/chooseUs/icon1.png";
import Box2 from "../images/chooseUs/icon2.png";
import Box3 from "../images/chooseUs/icon3.png";

function ChooseUs() {
  return (
    <>
      <section className="choose-section">
        <div className="container">
          <div className="choose-container">
            <img
              className="choose-container__img"
              src={MainImg}
              alt="car_img"
            />
            <div className="text-container">
              <div className="text-container__left">
                <h4>Why Choose Us</h4>
                <h2>Best valued deals you will ever find</h2>
                <p>
                Embark on a seamless car rental experience with CNC CARS Rental, situated in Rev. Leroy Hanna Dr Love Hill Subdivision, Central Andros, Bahamas. Unlock unbeatable deals on a fleet of reliable vehicles, catering to your diverse travel needs. Our commitment to affordability ensures you enjoy top-quality services and products without exceeding your budget. Take the wheel and explore Central Andros with our specially tailored car rental deals, providing you with the freedom to travel at your own pace. Don't miss out on the chance to save big on your next rental; CNC CARS is your go-to destination for hassle-free and budget-friendly car rentals in the Bahamas. Secure your ride today and make the most of your journey with CNC CAR & SCOOTER RENTAL.
                </p>
                <a href="#home">
                  Find Details &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>
              </div>
              <div className="text-container__right">
                <div className="text-container__right__box">
                  <img src={Box1} alt="car-img" />
                  <div className="text-container__right__box__text">
                    <h4>Cross Country Drive</h4>
                    <p>
                      Take your driving experience to the next level with our
                      top-notch vehicles for your cross-country <a
                        href="https://cnc-car-scooter-rental.business.site/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        adventures
                      </a> .
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box2} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>All Inclusive Pricing</h4>
                    <p>
                      Get everything you need in one convenient, transparent
                      price with our all-inclusive pricing policy.
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box3} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>No Hidden Charges</h4>
                    <p>
                      Enjoy peace of mind with our no hidden charges policy. We
                      believe in transparent and honest pricing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChooseUs;
