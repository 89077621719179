import React, { useState } from "react";
import { Link,  useNavigate } from "react-router-dom";
import Logo from "../images/logo/logo.png";

function Navbar() {

  const navigate = useNavigate();
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  const bookBtn = () => {
    // Check if the current location is already the home page
    if (window.location.pathname !== '/') {
      // Navigate to the home page
      navigate('/');
    }

    // After navigating to the home page, scroll to the pick-section
    setTimeout(() => {
      document
        .querySelector("#pick-section")
        .scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  return (
    <nav>
        
 <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', height: '22px', backgroundColor: '#ff4c30', marginBottom: '10px' }}>
  <a href="mailto:chrispinstore@gmail.com" style={{ color: 'white', textDecoration: 'none', marginRight: '40px', fontSize:'12px' }}> &#9993; chrispinstore@gmail.com</a>
  <a href="tel:+1 (242) 464-3036" style={{ color: 'white', textDecoration: 'none', fontSize:'12px' }}>&#x260E; +1 (242) 464-3036</a>
  </div> 

      <nav
        className={`navbar navbar-expand-lg navbar-light`}
        style={{
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
          height: "69px",
          //position: "fixed",
          backgroundColor: "white", marginTop:'22px'
        }}
      >
        <div className="container">
          {/* Logo */}
          <Link
            className="navbar-brand"
            to="/"
            onClick={() => window.scrollTo(0, 0)}
          >
            <img
              src={Logo}
              alt="logo-img"
              style={{ height: "40px", width: "auto" }}
            />
          </Link>

          {/* Mobile Menu Toggle */}
          <button className="navbar-toggler" type="button" onClick={toggleNav}>
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Links */}
          <div
            className={`collapse navbar-collapse ${navOpen ? "show" : ""}`}
            style={{ backgroundColor: navOpen ? "white" : "", width: "100%" }}
          >
            <ul
              className="navbar-nav ml-auto"
              style={{ paddingTop: "15px", paddingLeft: "4px" }}
            >
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={closeNav}
                  style={{
                    fontSize: "18px",
                    paddingLeft: "1px",
                    color: "#000",
                    transition: "color 0.3s",
                    marginLeft: "11px",
                  }}
                  onMouseOver={(e) => (e.target.style.color = "#ff4c30")} // Change color on hover
                  onMouseOut={(e) => (e.target.style.color = "black")} // Reset color on mouse out
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/about"
                  onClick={closeNav}
                  style={{
                    fontSize: "18px",
                    paddingLeft: "1px",
                    marginLeft: "11px",
                    color: "#000",
                    transition: "color 0.3s",
                  }}
                  onMouseOver={(e) => (e.target.style.color = "#ff4c30")} // Change color on hover
                  onMouseOut={(e) => (e.target.style.color = "black")} // Reset color on mouse out
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => {
                    bookBtn();
                    closeNav();
                  }}
                  style={{
                    fontSize: "18px",
                    paddingLeft: "1px",
                    marginLeft: "11px",
                    color: "#000",
                    transition: "color 0.3s",
                  }}
                  onMouseOver={(e) => (e.target.style.color = "#ff4c30")} // Change color on hover
                  onMouseOut={(e) => (e.target.style.color = "black")} // Reset color on mouse out
                >
                  Vehicle Models
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/testimonials"
                  onClick={closeNav}
                  style={{
                    fontSize: "18px",
                    paddingLeft: "1px",
                    marginLeft: "11px",
                    color: "#000",
                    transition: "color 0.3s",
                  }}
                  onMouseOver={(e) => (e.target.style.color = "#ff4c30")} // Change color on hover
                  onMouseOut={(e) => (e.target.style.color = "black")} // Reset color on mouse out
                >
                  Testimonials
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/contact"
                  onClick={closeNav}
                  style={{
                    fontSize: "18px",
                    paddingLeft: "1px",
                    marginLeft: "11px",
                    color: "#000",
                    transition: "color 0.3s",
                  }}
                  onMouseOver={(e) => (e.target.style.color = "#ff4c30")} // Change color on hover
                  onMouseOut={(e) => (e.target.style.color = "black")} // Reset color on mouse out
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </nav>
  );
}

export default Navbar;
