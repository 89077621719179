import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import PlanTrip from "../components/PlanTrip";
import PickCar from "../components/PickCar";
import Banner from "../components/Banner";
import ChooseUs from "../components/ChooseUs";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import Models from "./Models";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <Helmet>
        <title>
          CNC Cars Rental - CNC CAR & SCOOTER RENTAL Service Located in Central
          Andros, Bahamas
        </title>

        <meta
          name="description"
          content="Discover the best car rental deals with CNC Cars Rental. Providing top-quality car renting services in Central Andros, Bahamas. Your ultimate car renting experience awaits with CNC CAR & SCOOTER RENTAL!"
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <Hero />
      <PickCar />
      <PlanTrip />
      <BookCar />
      <Banner />
      <ChooseUs />
      <Testimonials />
      <Faq />
      <Footer />
    </>
  );
}

export default Home;
