function Footer() {



  function handleSubscribe() {
    alert('Thanks for subscribing!');
  }



  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">


          <ul className="footer-content__2">
              <li>Contact Us</li>
              
              <li style={{ fontSize: "14px", fontWeight:'bold' }}  >
                <a href="tel:+1 (242) 464-3036">
                &#x260E; +1 (242) 464-3036
                </a>
              </li>

              <li  style={{ fontSize: "14px", fontWeight:'bold' }} >
                <a
                  href="mailto: 
                  chrispinstore@gmail.com"
                >
                
                  &#9993;  chrispinstore@gmail.com
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px", fontWeight:'bold' }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://maps.app.goo.gl/UGqFFZBpH6wPW2Ja7"
                 // href="https://jephunneh.com/"
                >
                 &#128205; 
                    Central Andros, Bahamas
                </a>
              </li>
              
            </ul>



            <ul className="footer-content__1">
              <li>
                <span style={{color:'rgb(252,76,44)'}}>CNC</span>   <span>  Cars Rental </span>
              </li>
             
             

              <li  style={{ fontSize: "13px" }}>
                We offers a big range of vehicles for all your car rental needs. We
                have the perfect car to meet your needs. So rent a car with CNC CAR & SCOOTER RENTAL now with ease.
              </li>

              <li>
                <a
                  style={{ fontSize: "14px"}}
                  target="_blank"
                  rel="noreferrer"
                  href="https://dev2code.com"
                >
                  <span>Website By:</span> 
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px"}}
                  target="_blank"
                  rel="noreferrer"
                  href="https://web4business.tech" 
                >
                  <span style={{color:'rgb(252,76,44)' }}>Qasim</span> 
                </a>
              </li>

  
            </ul>

            <ul className="footer-content__2">
              <li>Company</li>
              <li>
                <a href="/contact">Bahamas</a>
              </li>
              <li>
                <a href="/contact">Careers</a>
              </li>
              <li>
                <a href="https://cnc-car-scooter-rental.business.site">Mobile</a>
              </li>
              
              <li>
              <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://cncadmin.netlify.app/"
                 // href="https://jephunneh.com/"
                >
              
                   Admin  &#128187;
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Working Hours</li>
              <li>Mon - Sat: 8:00AM - 7:00PM</li>
              <li>Sun: Closed</li>
            </ul>

          
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
