import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import PlanTrip from "../components/PlanTrip";
import AboutMain from "../images/about/about-main.jpg";
import Box1 from "../images/about/icon1.png";
import Box2 from "../images/about/icon2.png";
import Box3 from "../images/about/icon3.png";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <title>About Us - CNC CAR & SCOOTER RENTAL</title>

        <meta
          name="description"
          content="Learn about CNC Cars Rental, your go-to destination for affordable and diverse vehicle rental options in Central Andros, Bahamas. Discover reliable transportation solutions with a fleet of well-maintained vehicles, competitive pricing, and a dedication to customer satisfaction. Explore the convenience of reliable transportation at your fingertips with NC CAR & SCOOTER RENTAL in the beautiful Bahamas."
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <section className="about-page">
        <HeroPages name="About" />
        <div className="container">
          <div className="about-main">
            <img
              className="about-main__img"
              src={AboutMain}
              alt="car-renting"
            />
            <div className="about-main__text">
              <h3>About Company</h3>
              <h2>You start the engine and your adventure begins</h2>
              <p>
                CNC Cars Rental, situated in the heart of Ev. Leroy Hanna Dr
                Love Hill Subdivision, Central Andros, Bahamas, is your go-to
                destination for affordable and diverse vehicle rental options.
                With a commitment to providing reliable transportation
                solutions, CNC CAR & SCOOTER RENTAL offers a fleet of vehicles
                to suit various preferences and needs. Whether you're exploring
                the scenic landscapes of Central Andros or navigating the
                bustling streets, CNC Car Rental ensures a hassle-free
                experience with well-maintained vehicles, competitive pricing,
                and a dedication to customer satisfaction. Discover the
                convenience of reliable transportation at your fingertips with
                CNC CAR & SCOOTER RENTAL in the beautiful Bahamas.
              </p>
              <div className="about-main__text__icons">
                <div className="about-main__text__icons__box">
                  <img src={Box1} alt="car-icon" />
                  <span>
                    <h4>11</h4>
                    <p>Cars </p>
                  </span>
                </div>
                <div className="about-main__text__icons__box">
                  <img src={Box2} alt="car-icon" />
                  <span>
                    <h4>1</h4>
                    <p>
                      <a
                        href="https://cnc-car-scooter-rental.business.site/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Rental Outlet
                      </a>
                    </p>
                  </span>
                </div>
                <div className="about-main__text__icons__box">
                  <img src={Box3} alt="car-icon" className="last-fk" />
                  <span>
                    <h4>1</h4>
                    <p>Repair Shop</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <PlanTrip />
        </div>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div
            className="text-content"
            style={{
              display: "flex",
              alignItems: "center" /* Center vertically */,
              justifyContent: "center",
            }}
          >
            <h2> To Book Car </h2>
            <p>
              <a href="/#booking-section">
                <span>Click Here</span>
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
