import { useState, useEffect } from "react";
import CarBox from "./CarBox";
import { Link } from "react-router-dom";
import { db } from "../components/firebase.js";
import { getDocs, collection } from "firebase/firestore";
function PickCar() {
  const [cars, setCars] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const data = querySnapshot.docs.map((doc) => doc.data());
        setCars(data); // Update this line
        // setCarTypes(data.map((car) => car.name));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [debouncedReserveNow, setDebouncedReserveNow] = useState(null);

  const handleReserveNow = () => {
    // Your logic for handling Reserve Now
    window.location.href = "#booking-section"; // Example: change URL
  };

  const debounce = (func, delay) => {
    if (debouncedReserveNow) {
      clearTimeout(debouncedReserveNow);
    }

    const timeout = setTimeout(() => {
      func();
      setDebouncedReserveNow(null);
    }, delay);

    setDebouncedReserveNow(timeout);
  };

  const handleClick = () => {
    debounce(handleReserveNow, 1000); // Adjust the debounce delay according to your needs
  };

  return (
    <section id="pick-section" className="pick-section">
      <div className="container">
        <div className="pick-container__title">
          <h3>Vehicle Models</h3>
          <h2>Our rental fleet</h2>
          <p>
            Choose from a variety of our amazing vehicles to rent for your next
            adventure or business trip
          </p>
        </div>

        <div className="models-div">
          {cars.map((car) => (
            <div
              key={car.id} // Make sure to add a unique key to each mapped element
              className="models-div__box"
              style={{
                backgroundColor: "#FFF5EE",
                borderWidth: "3px",
                borderColor: "#ff4c30",
                transition: "transform 0.3s ease", // Add a smooth transition effect
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.1)")
              } // Zoom in on hover
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")} // Restore original size on mouse out
            >
              <div className="models-div__box__img">
                <img src={car.img} alt="car_img" style={{ height: "230px" }} />
                <div className="models-div__box__descr">
                  <div className="models-div__box__descr__name-price">
                    <div className="models-div__box__descr__name-price__name">
                      <p>{car.name}</p>
                    </div>
                    <div className="models-div__box__descr__name-price__price">
                      <h4>${car.price}</h4>
                      <p>per day</p>
                    </div>
                  </div>
                  <div className="models-div__box__descr__name-price__details">
                    <span>&#x2022;{car.transmission}</span>
                    <span style={{ textAlign: "right" }}>
                      &#x2022;{car.fuel}
                    </span>
                    <span>&#x2022;{car.air}</span>
                  </div>

                  <button
                    className="models-div__box__descr__name-price__btn"
                    style={{ height: "60px" }}
                    onClick={handleClick}
                  >
                    <a href="#booking-section">Reserve Now</a>
                  </button>
                  
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default PickCar;
