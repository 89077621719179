import { Link } from "react-router-dom";
import BgShape from "../images/hero/hero-bg.png";
import HeroCar from "../images/hero/main-car.png";
import { useEffect, useState } from "react";
import whatsapp from "../images/logo/whatsapp.png";

function Hero() {
  const [goUp, setGoUp] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const bookBtn = () => {
    document.querySelector('#booking-section').scrollIntoView({ behavior: 'smooth' });
  };

  const openWhatsApp = () => {
    window.open('https://wa.me/12424643036', '_blank');
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.pageYOffset > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('scroll', onPageScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', onPageScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section id="home" className="hero-section">
      <div className="container">
        <img
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
            height: '90%',
          }}
          src={BgShape}
          alt="bg-shape"
        />
        <div className="hero-content">
          <div className="hero-content__text">
            <h4>Plan your trip now</h4>
            <h1>
              Save big @ <span>CNC</span> Cars Rental
            </h1>
            {/* Responsive paragraph */}
            <div style={{height:'110px'}}>
            {windowWidth >= 1100 ? (
              <div style={styles.container}>
                <p>
                  Rent the car of your dreams with CNC Cars Rental. Unbeatable prices, unlimited miles,
                  flexible pick-up options, and much more.
                </p>
              </div>
              
            ) : null}
 </div>

            <div className="hero-content__text__btns">
              <Link onClick={bookBtn} className="hero-content__text__btns__book-ride" to="/">
                Book Ride <i className="fa-solid fa-circle-check"></i>
              </Link>
              <a
                style={{ backgroundColor: 'green' }}
                href="https://maps.app.goo.gl/UGqFFZBpH6wPW2Ja7"
                target="_blank"
                rel="noopener noreferrer"
                className="hero-content__text__btns__book-ride"
              >
                Location
              </a>
              <Link className="hero-content__text__btns__book-ride" to="/contact">
                Contact Us <i className="fa-solid fa-angle-right"></i>
              </Link>
            </div>
          </div>

          {/* Car Image */}
          <img
            src={HeroCar}
            alt="car-img"
            style={{
              zIndex: 2,
              position: 'absolute',
              right: 0,
              width: '65%',
              marginTop: '5rem',
            }}
          />
        </div>
      </div>

      {/* WhatsApp button */}
      <div
        onClick={openWhatsApp}
        className="whatsapp-btn"
        style={{
          position: 'fixed',
          bottom: '35px',
          left: '3px',
          zIndex: '1000',
        }}
      >
        <img src={whatsapp} alt="Whatsapp-img" style={{ height: 80, width: 75 }} />
      </div>

      {/* Scroll to top button */}
      <div onClick={scrollToTop} className={`scroll-up ${goUp ? 'show-scroll' : ''}`}>
        <p style={{ fontSize: 22 }}>&#9650;</p>
      </div>
    </section>
  );
}

export default Hero;

const styles = {
  container: {
   // height: '110px',
    display: 'block', // Ensure it's a block element
  },
  // Media query to hide the container when the screen width is less than 500px
  '@media (max-width: 800px)': {
    container: {
      display: 'none',
    },
  },
};