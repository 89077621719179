

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage'
import 'firebase/compat/firestore';

const firebaseConfig = {

    apiKey: "AIzaSyA1k2dFvLPhZzxFcPnMFXcK54vHgRoxJ_s",
  authDomain: "cnccarrental-b496d.firebaseapp.com",
  projectId: "cnccarrental-b496d",
  storageBucket: "cnccarrental-b496d.appspot.com",
  messagingSenderId: "107111406198",
  appId: "1:107111406198:web:c1656cfb992abf2929dd57"
  
  };
  
  
  
let app;

if (firebase.apps.length === 0) {
    app = firebase.initializeApp(firebaseConfig)
} else {
    app = firebase.app();
}

const db = app.firestore();


export {db} 